import React from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Box, Button } from '@chakra-ui/react'

import Layout from '../components/layout'
import FormWedding from '../components/formWedding'
import Services from '../components/services'

import { Article, P, Image, Section, RowContent, ColImage, ColInfo, Gallery } from '../ui'

function WeddingsPage({ data }) {
  const { t } = useTranslation()

  return (
    <Layout title={t('weddings:title')}>
      <Article>
        <Section
          title={t('weddings:title')}
          titleAlign="center"
          subtitle={t('weddings:desc')}
          spacing={10}
        >
          <RowContent>
            <ColImage
              image={<Image fixed={data.featured1.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo title={t('weddings:sections.one.title')} backgroundColor="gray.100">
              <P>{t('weddings:sections.one.desc1')}</P>
              <P>{t('weddings:sections.one.desc2')}</P>
              <Button as="a" href="#contact" width="fit-content" colorScheme="primary">
                {t('weddings:bookEvent')}
              </Button>
            </ColInfo>
          </RowContent>

          <RowContent reversedInMobile>
            <ColInfo title={t('weddings:sections.two.title')} backgroundColor="gray.100">
              <P>{t('weddings:sections.two.desc1')}</P>
              <P>{t('weddings:sections.two.desc2')}</P>
              <Button as="a" href="#contact" width="fit-content" colorScheme="primary">
                {t('weddings:bookEvent')}
              </Button>
            </ColInfo>
            <ColImage
              image={<Image fixed={data.featured2.childImageSharp.fixed} layout="background" />}
            />
          </RowContent>
        </Section>

        <Section
          title={t('weddings:gallery.title')}
          subtitle={t('weddings:gallery.desc')}
          titleAlign="center"
        >
          <Gallery
            images={data.gallery.edges.map((image) => (
              <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
            buttonText={t('common:viewPhotos')}
          />
        </Section>

        <Section
          id="contact"
          title={t('weddings:contact.title')}
          subtitle={t('weddings:contact.desc')}
          titleAlign="center"
          backgroundColor="gray.100"
        >
          <Box
            backgroundColor="white"
            boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
            padding={{ base: 6, md: 8 }}
            borderRadius="15px"
            maxWidth={{ base: '100%', md: '720px' }}
            marginX={{ base: 0, md: 'auto' }}
          >
            <FormWedding
              hideOnSuccess
              onSubmit={() => {}}
              event={{
                label: t('forms:wedding.typeOfEvent.label'),
                required: t('forms:wedding.typeOfEvent.required'),
                invalid: t('forms:wedding.typeOfEvent.invalid'),
                placeholder: t('forms:wedding.typeOfEvent.placeholder'),
              }}
              phone={{
                label: t('forms:phone.label'),
                required: t('forms:phone.required'),
                invalid: t('forms:phone.invalid'),
                placeholder: t('forms:phone.placeholder'),
              }}
              email={{
                required: t('forms:email.required'),
                invalid: t('forms:email.invalid'),
                placeholder: t('forms:email.placeholder'),
              }}
              name={{
                label: t('forms:name.label'),
                required: t('forms:name.required'),
                invalid: t('forms:name.invalid'),
                placeholder: t('forms:name.placeholder'),
              }}
              firstDate={{
                label: t('forms:wedding.firstDate.label'),
                required: t('forms:wedding.firstDate.required'),
                invalid: t('forms:wedding.firstDate.invalid'),
                placeholder: t('forms:wedding.firstDate.placeholder'),
              }}
              guests={{
                label: t('forms:wedding.guests.label'),
                required: t('forms:wedding.guests.required'),
                invalid: t('forms:wedding.guests.invalid'),
              }}
              message={{
                label: t('forms:message.label'),
              }}
              successText={t('forms:messages.sent')}
              buttonText={t('forms:buttons.askQuote')}
            />
          </Box>
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    featured1: file(relativePath: { eq: "weddings/IMG_4161.JPG" }) {
      ...fixedImage
    }

    featured2: file(relativePath: { eq: "weddings/leonardo-miranda-dvF6s1H1x68-unsplash.jpg" }) {
      ...fixedImage
    }

    gallery: allFile(filter: { relativeDirectory: { eq: "weddings" } }, sort: { fields: name }) {
      ...multipleFixedImage
    }
  }
`

export default WeddingsPage
